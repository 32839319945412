@import "/src/variable.scss";

.headerContainer {
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain;
  background-color: $backGround-color2;
  padding-top: 70px;
  padding-bottom: 50px;
  .headerTitle {
    margin-top: 5rem;
    margin-bottom: 2.5rem;
    font-weight: 600;
    font-size: 70px;
    line-height: 40px;
    text-align: center;
    font-feature-settings: "salt" on, "liga" off;
  }
  .headerDesc {
    font-weight: 400;
    font-size: 22px;
    line-height: 34px;
    font-feature-settings: "salt" on, "liga" off;
    text-align: center;
    margin-bottom: 60px;
  }
  .headerImg {
    width: 100%;
  }
}
.section1 {
  padding-top: 150px;
  padding-bottom: 150px;
  color: $color-white;
  .section1TextContainer {
    padding: 0 10%;
  }
  .title {
    font-weight: 600;
    font-size: 50px;
    line-height: 61px;
  }
  .label {
    font-weight: 700;
    font-size: 28px;
    line-height: 30px;
  }
  .text1 {
    font-weight: 500;
    font-size: 28px;
    line-height: 30px;
  }
}

.section2 {
  display: flex;
  justify-content: center;
  background-color: $backGround-color2;
  padding-top: 50px;
  padding-bottom: 50px;
  img {
    padding: 20px;
    border: 4px solid $color-white;
    border-radius: 25px;
    width: 80%;
  }
}
.section3 {
  padding-top: 220px;
  padding-bottom: 50px;
  .title {
    font-weight: 600;
    font-size: 50px;
    line-height: 66px;
    margin-bottom: 35px;
  }
  .desc {
    font-weight: 500;
    font-size: 28px;
    line-height: 45px;
    text-align: justify;
    margin-bottom: 141px;
  }
  img {
    width: 80%;
  }
}
.section4 {
  padding-top: 50px;
  padding-bottom: 220px;
  .title {
    font-weight: 600;
    font-size: 50px;
    line-height: 66px;
    margin-bottom: 35px;
  }
  .desc {
    font-weight: 500;
    font-size: 28px;
    line-height: 45px;
    text-align: justify;
    margin-bottom: 141px;
  }
  img {
    width: 90%;
    padding: 20px;
    border: 4px solid $color-white;
    border-radius: 25px;
  }
}
.section4_2 {
  padding-top: 50px;
  padding-bottom: 220px;
  .title {
    font-weight: 600;
    font-size: 50px;
    line-height: 66px;
    margin-bottom: 35px;
  }
  .desc {
    font-weight: 500;
    font-size: 28px;
    line-height: 45px;
    text-align: justify;
    margin-bottom: 141px;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
.sectionImg {
  img {
    width: 100%;
    height: 100%;
  }
}
@media screen and (min-width: 1500px) {
  .headerContainer {
  }
}
@media screen and (max-width: 576px) {
  .section1 {
    padding-top: 70px;
    padding-bottom: 70px;
    color: $color-white;
    .section1TextContainer {
      padding: 0 0;
    }
    .title {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 15px;
    }
    .label {
      font-size: 18px;
      line-height: 22px;
    }
    .text1 {
      font-size: 16px;
      line-height: 20px;
    }
  }
  
  .section2 {
    padding-top: 50px;
    padding-bottom: 50px;
    img {
      width: 100%;
    }
  }
  .section3 {
    padding-top: 70px;
    padding-bottom: 25px;
    .title {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 20px;
    }
    .desc {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 50px;
    }
    img {
      width: 100%;
    }
  }
  .section4 {
    padding-top: 25px;
    padding-bottom: 70px;
    .title {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 20px;
    }
    .desc {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 50px;
    }
    img {
      width: 100%;
      margin-bottom: 50px;
    }
  }
  .section4_2 {
    padding-top: 25px;
    padding-bottom: 0px;
    .title {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 20px;
    }
    .desc {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 50px;
    }
    img {
      width: 100%;
      padding-bottom: 100px;
      margin-bottom: 50px;
    }
  }
  .sectionImg {
    img {
      width: 100%;
      height: 100%;
    }
  }
  
}
