@import "/src/variable.scss";

.companyColabText {
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 2.7rem;
  margin-bottom: 5rem;
}
.brandContainer {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  background-color: $color-white;
  gap: 1px;
}
.brandImageContainer {
  min-height: 126px;
  padding: 10%;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: $backGround-color;
  img {
    max-width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .brandContainer {
    grid-template-columns: 50% 50%;
  }
}
