@import "/src/variable.scss";


.btn_more {
  color: $color-white;
  border-radius: 50vh;
  background-color: transparent;
  border: 1px solid white;
  padding: 1.9rem;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  transition: all ease-in-out 0.2s;
}
.btn_more:hover {
  background-color: $theme-color;
  border: 1px solid $theme-color;
}

.commentCarousel {
  color: $color-white;
}
.container1 {
  margin-bottom: 10rem;
  .title {
    font-size: 5rem;
    line-height: 7.1rem;
    font-weight: 600;
  }
}
.btnContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.sliderContiner {
  border-radius: 2rem;
  margin: auto;
  margin-bottom: 25px;
  margin-left: 5%;
}
.cardContainer {
  height: 600px;
  background-color: $backGround-color2;
  padding: 4rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin: 20px;
  img {
    margin-bottom: 2rem;
  }
}
.companyName {
  font-weight: 600;
  font-size: 35px;
  line-height: 60px;
  font-feature-settings: "salt" on, "liga" off;
  margin-bottom: 16px;
}
.containerBackbround {
  background-repeat: no-repeat !important;
  background-size: 100%;
  background-position: bottom right;
  height: 100%;
}
@media screen and (min-width: 1500px) {
  .sliderContiner {
    margin-left: 12%;
  }
}
@media screen and (max-width: 576px) {
  .containerBackbround {
    background-size:contain;
  }
  .container1 {
    margin-bottom: 4rem;
    .title {
      font-size: 3.2rem;
      line-height: 5rem;
    }
  }
  .btnContainer {
    justify-content: center;
  }
  .companyName {
    font-size: 28px;
    line-height: 34px;
  }

  .cardContainer {
    padding: 2rem;
    height: 400px;
    img{
      max-width: 100%;
    }
  }
  .btn_more {
    padding: 0.9rem;
  }
}
