@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);


$backGround-color: rgba(0, 12, 36, 1);
$backGround-color2: rgba(11, 29, 78, 1);
$backGround-color3:rgba(22, 35, 76, 1);
$backGround-color4:rgba(5, 18, 43, 1);
$theme-color: rgba(25, 84, 244, 1);
$divider-color: rgba(217, 217, 217, 1);
$color-white: rgba(255, 255, 255, 1);
$color-whiteSmoke: rgba(243, 243, 243, 1);
$color-lightGray: rgba(183, 183, 183, 1);


$header_link_inactive: #a0a0a0;
$header_link_inactive-hover: #cbcbcb;
$header_link_active: rgb(255, 255, 255);
// fonts
$default-font:'Montserrat', sans-serif;
$font-2:'Montserrat', sans-serif;
