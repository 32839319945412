@import "/src/variable.scss";

.container1 {
  margin-bottom: 200px;
}

.C1_title1 {
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 1.8rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 3.5rem;
  color: $color-white;
}
.C1_title2 {
  font-weight: 800;
  font-size: 5rem;
  line-height: 7.1rem;
  margin-bottom: 10px;
  color: $color-white;
  span {
    color: $theme-color;
  }
}

.C1_text1 {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3.8rem;
  color: $color-white;
  margin-bottom: 8rem;
  // text-align: justify;
}
.contactUs {
  padding-top: 100px;
}
.containerForm {
  position: absolute;
  width: 50%;
  right: 0;
  top: 100px;
}

.C1_right_card {
  background: $backGround-color2;
  box-shadow: 0px 30px 80px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  padding: 30px;
}
.formTitle {
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 2.8rem;
  color: $color-white;
  margin-bottom: 2.4rem;
}
.formDesc {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 3rem;
  letter-spacing: 0.2px;
  color: #e4e4e4;
}
.formLabel {
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.6rem;
  letter-spacing: 0.2px;
  color: $color-white;
  margin-bottom: 14px;
}
.formInput {
  background: $backGround-color;
  color: $color-white;
  border: none;
  border-radius: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.175px;
  padding: 17px 18px;
  width: 100%;
}
.formInput:hover {
  outline: 1px solid rgba(255, 255, 255, 0.4);
}

.formInput:focus-visible {
  outline: 1px solid rgba(255, 255, 255, 0.7);
}

.form_btnSubmit {
  background: $theme-color;
  border-radius: 10px;
  width: 100%;
  padding: 17px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #ffffff;
  &:disabled{
    opacity: 0.6;
  }
}

.container3 {
  margin-bottom: 180px;
}
.C3_row1 {
  margin-bottom: 80px;
}

.C3_title1 {
  font-weight: 700;
  font-size: 6rem;
  line-height: 6rem;
  color: $color-white;
  margin-bottom: 0;
}

.C3_text1 {
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #dbdbdb;
  margin-bottom: 0;
}

.contactCard {
  height: 100%;
  background: $backGround-color2;
  border: none;
  border-radius: 20px 20px;
  .cardBody {
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: contain;
    height: 100%;
    padding: 3rem;
    padding-bottom: 50%;
    color: $color-white;
    .location {
      font-weight: 500;
      font-size: 5rem;
      margin-bottom: 3rem;
    }
    .address {
      font-weight: 400;
      font-size: 1.8rem;
      margin-bottom: 10px;
    }
    .email {
      font-weight: 400;
      font-size: 1.8rem;
      margin-bottom: 20px;
      a {
        color: $color-white;
        text-decoration: none;
      }
    }
    .email:nth-last-child(2) {
      margin-bottom: 40px;
    }
    .btnMap {
      text-decoration: none;
      color: $color-white;
      background-color: $theme-color;
      padding: 2rem 5rem;
      border-radius: 50vh;
      margin-top: 100px;
      font-size: 1.7rem;
      font-weight: 700;
    }
  }
}

.message {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 18px;
  height: 18px;
}

.tick{
  background-color: green;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  margin-right: 5px;
}

.error{
  background-color: red;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  margin-right: 5px;
}

@media screen and (max-width: 576px) {
  .container1 {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 80px;
  }
  .C1_title1 {
    font-size: 1.4rem;
    line-height: 1.7rem;
    text-align: center;
    letter-spacing: 2.57px;
    margin-bottom: 10px;
  }
  .C1_title2 {
    font-size: 3.2rem;
    line-height: 4.6rem;
    text-align: center;
    margin-bottom: 16px;
  }
  .C1_text1 {
    font-size: 1.6rem;
    line-height: 2.8rem;
    text-align: center;
    margin-bottom: 16px;
  }

  .formTitle {
    font-size: 18px;
    line-height: 22px;
  }
  .formDesc {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.2px;
  }
  .formLabel {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1px;
    margin-bottom: 5px;
  }
  .container2 {
    padding-top: 10px;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .C3_row1 {
    margin-bottom: 28px;
  }
  .C3_title1 {
    font-size: 34px;
    line-height: 46px;
    text-align: center;
    margin-bottom: 16px;
  }
  .C3_text1 {
    font-size: 13px;
    line-height: 23px;
    text-align: center;
  }
  .container3 {
    margin-bottom: 40px;
  }
}
