@import "/src/variable.scss";

.container1 {
  color: $color-white;
}
.heading1 {
  padding-top: 143px;
  margin-bottom: 150px;
  font-weight: 700;
  font-size: 5rem;
  line-height: 6.4rem;
  letter-spacing: 0.03em;
  font-feature-settings: "salt" on, "liga" off;
  span {
    color: $theme-color;
  }
}

.workContainer {
  color: $color-white;
  margin-bottom: 10rem;
  padding: 0 2%;
}

.workImage {
  width: 100%;
}
.textContainer {
  padding: 0 10%;
}
.workTitle {
  margin-top: 5rem;
  margin-bottom: 5rem;
  font-weight: 600;
  font-size: 50px;
  line-height: 40px;

  font-feature-settings: "salt" on, "liga" off;
}
.workDesc {
  margin-bottom: 5rem;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  font-feature-settings: "salt" on, "liga" off;
}
@media screen and (min-width: 1500px) {
  .workContainer {
    padding: 0 6%;
  }
  .textContainer {
    padding: 0 12%;
  }
}
@media screen and (max-width: 576px) {
  .heading1 {
    padding-top: 10rem;
    margin-bottom: 7rem;
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
  .workImage{
    margin-bottom: 2.5rem;
  }
  .workContainer {
    padding: 0 20px;
    margin-bottom: 7rem;
  }
  .textContainer {
    padding: 0 20% 0 0;
  }
  .workTitle {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    font-size: 22px;
    line-height: 30px;
  }
  .workDesc {
    margin-bottom: 2.5rem;
    font-size: 12px;
    line-height: 24px;
  }
}
