@import "/src/variable.scss";

.homeContainer {
  padding-top: 16rem;
  margin-bottom: 22rem;
}
.container1 {
  margin-bottom: 16rem;
}
.heading {
  color: $color-white;
  font-family: $default-font;
  font-size: 5rem;
  margin-bottom: 1.8rem;
  line-height: 8rem;
  font-weight: 700;
}
.heading span {
  color: $theme-color;
}
.text {
  color: $color-white;
  font-size: 2rem;
  line-height: 4rem;
  font-weight: 400;
  margin-bottom: 0;
  text-align: justify;
}

.btnContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.summary {
  color: $color-white;
  margin-top: 6.4rem;
  align-items: center;
  .summaryHeading {
    font-weight: 600;
    font-size: 2rem;
    line-height: 3.4rem;
    letter-spacing: 0.2px;
    margin-bottom: 0;
    color: $color-whiteSmoke;
  }
  .summaryCount {
    font-weight: 700;
    font-size: 4.8rem;
    line-height: 5.9rem;
    margin-bottom: 1.3rem;
  }
  .summaryTitle {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.2rem;
    letter-spacing: 0.18px;
    margin-bottom: 0;
    color: $color-lightGray;
  }
}
.container2 {
  background-color: $backGround-color4;
  color: $color-white;
  padding: 68px 0;
  .serviceTitle {
    margin-bottom: 5rem;
  }
}
.container3 {
  padding: 22rem 0;
  color: $color-white;
  .heading1 {
    font-weight: 700;
    font-size: 4.8rem;
    line-height: 6.8rem;
    margin-bottom: 3rem;
    span {
      color: $theme-color;
    }
  }
}
.heading1 {
  font-weight: 700;
  font-size: 6rem;
  line-height: 6.5rem;
  margin-bottom: 3rem;
  span {
    color: $theme-color;
  }
}
.text1 {
  font-weight: 400;
  font-size: 2rem;
  line-height: 4rem;
  margin-bottom: 8.4rem;
}

@media screen and (max-width: 576px) {
  .homeContainer {
    padding-top: 10rem;
    margin-bottom: 10rem;
  }
  .container1 {
    margin-bottom: 4rem;
  }
  .heading {
    font-size: 2.6rem !important;
    margin-bottom: 1.8rem;
    line-height: 3.8rem;
    text-align: center;
  }
  .text {
    font-size: 1.6rem;
    line-height: 2.8rem;
    text-align: justify;
  }
  .summary {
    .summaryHeading {
      font-size: 1.6rem;
      line-height: 2.8rem;
      margin-bottom: 4.9rem;
    }
  }
  .container2{
    background-color: $backGround-color;
    padding: 0px 0;
    .serviceTitle{
      margin-bottom: 0rem;
    }
  }
  .heading1 {
    font-size: 2.6rem;
    line-height: 2.8rem;
    margin-bottom: 2rem;
    text-align: center;
  }
  .text1 {
    font-size: 1.5rem;
    line-height: 2.8rem;
    margin-bottom: 5rem;
    text-align: center;
  }
  .container3 {
    padding: 10rem 0;
    .heading1 {
      font-size: 2.6rem;
      line-height: 3.8rem;
      margin-bottom: 2rem;
      span {
        color: $theme-color;
      }
    }
  }
}
