@import "/src/variable.scss";

.titleBox {
  flex-wrap: nowrap;
  padding: 30px 20px;
}
.body {
  padding: 0px 20px 30px 20px;
  background-color: $backGround-color3;
}
.title {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 2.9rem;
  letter-spacing: 0.2px;
  color: $color-white !important;
  max-width: 450px;
  margin: 0;
  padding-right: 10px;
}

.text {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.8rem;
  letter-spacing: 0.133333px;
  color: $color-white;
  text-align: justify;
  width: 90%;
  margin: 0;
}

@media screen and (max-width: 576px) {
  .title {
    font-size: 15px;
    line-height: 23px;
  }
  .text {
    font-size: 14px;
    line-height: 24px;
    width: 100%;
  }
}
