@import "/src/variable.scss";

.commentCarousel {
  color: $color-white;
  .title {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 5rem;
    line-height: 7.1rem;
    font-weight: 700;
    margin-bottom: 10rem;
    span {
      color: $theme-color;
    }
  }
  .sliderContiner {
    max-width: 1000px;
    border-radius: 2rem;
    margin: auto;
    margin-bottom: 25px;
    .cardContainer {
      padding: 5rem 9rem;
      background-repeat: no-repeat !important;
      background-size: 12rem !important;
      .comment {
        font-weight: 500;
        font-size: 3.2rem;
        line-height: 5.2rem;
        margin-bottom: 0;
        letter-spacing: 0.29px;
        margin-bottom: 4rem;
      }
      img {
        width: 5.2rem;
        margin-right: 2rem;
      }
      .username {
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 2.2rem;
      }
      .designatom {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.7rem;
        opacity: 0.75;
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .commentCarousel{
  .title {
    font-size: 1.6rem;
    line-height: 2.8rem;
    padding: 0 30px;
    margin-bottom: 2rem;
  }
}
  .cardContainer {
    padding: 6rem 1rem 5rem 5rem !important;
    .comment {
      font-size: 1.6rem !important;
      line-height: 2.8rem !important;
    }
  }
}
