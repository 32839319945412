@import "/src/variable.scss";


.sectionlast{
  padding-top: 100px;
  padding-bottom: 100px;
}
@media screen and (max-width: 576px) {
  .sectionlast{
    padding-top: 70px;
    padding-bottom: 10px;
  }
}
