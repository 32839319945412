@import "/src/variable.scss";

.headerContainer {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain;
  background-color: $backGround-color2;
  padding: 120px 0;
  .headerRightImg {
    width: 100%;
  }
  .headerTextContainer {
    color: $color-white;
    padding:0 10%;
    .headerTitle {
      margin-top: 5rem;
      margin-bottom: 2.5rem;
      font-weight: 600;
      font-size: 46px;
      font-feature-settings: "salt" on, "liga" off;
    }
    .headerDesc {
      font-weight: 400;
      font-size: 22px;
      line-height: 34px;
      font-feature-settings: "salt" on, "liga" off;
    }
  }
}
.section1 {
  padding-top: 150px;
  padding-bottom: 150px;
  color: $color-white;
  .section1TextContainer {
    padding: 0 10%;
  }
  .title {
    font-weight: 600;
    font-size: 50px;
    line-height: 61px;
  }
  .label {
    font-weight: 700;
    font-size: 28px;
    line-height: 30px;
  }
  .text1 {
    font-weight: 500;
    font-size: 28px;
    line-height: 30px;
  }
}
.sectionImg {
  img {
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 1500px) {
  .headerContainer {
    min-height: auto;
    padding: 120px 0 70px 0;
    .headerTextContainer {
      padding:0 24%;
    }
  }
}

@media screen and (max-width: 576px) {
  .headerContainer {
    min-height: auto;
    padding: 120px 0 70px 0;
    .headerTextContainer {
      padding:0 24px;
      .headerTitle {
        margin-top: 5rem;
        margin-bottom: 2.5rem;
        font-weight: 600;
        font-size: 46px;
        font-feature-settings: "salt" on, "liga" off;
      }
      .headerDesc {
       text-align: justify;
      }
    }
  }
  .section1 {
    padding-top: 70px;
    padding-bottom: 70px;
    color: $color-white;
    .section1TextContainer {
      padding: 0 0;
    }
    .title {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 15px;
    }
    .label {
      font-size: 18px;
      line-height: 22px;
    }
    .text1 {
      font-size: 16px;
      line-height: 20px;
    }
  }
  .sectionImg {
    img {
      width: 100%;
      height: 100%;
    }
  }
}
