@import '/src/variable.scss';

.section1 {
  padding-top: 150px;
  padding-bottom: 150px;
  color: $color-white;

  .headerRightImg {
    max-width: 100%;
  }

  .title {
    font-weight: 600;
    font-size: 50px;
    line-height: 61px;
  }

  .headerDesc {
    font-weight: 400;
    font-size: 22px;
    line-height: 34px;
    font-feature-settings: 'salt' on, 'liga' off;
  }
}

.section1:nth-child(odd){
  background-color: $backGround-color2;
}

@media screen and (max-width: 576px) {
  .section1 {
    padding-top: 70px;
    padding-bottom: 70px;
    .title {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 20px;
    }
    .headerDesc {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 50px;
      text-align: justify;
    }
    img {
      width: 100%;
    }
  }
  
  .section1:nth-child(odd){
    background-color: $backGround-color2;
  }
  
}