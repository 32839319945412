@import "/src/variable.scss";


.container2,
.container3 {
  color: $color-white;
  margin-bottom: 22rem;
}
.container1 {
  color: $color-white;

  padding-top: 16rem;
  margin-bottom: 16rem;
}

.heading1 {
  font-weight: 700;
  font-size: 5rem;
  line-height: 8rem;
  letter-spacing: 1px;
  span {
    color: $theme-color;
  }
}
.text1 {
  font-weight: 500;
  font-size: 2rem;
  line-height: 3.5rem;
  text-align: justify;
}
.imageContainer {
  width: 100%;
  padding: 0 5%;
  margin-bottom: 22rem;
  img {
    width: 100%;
  }
}

.heading2 {
  font-weight: 700;
  font-size: 4.5rem;
  line-height: 5.5rem;
  letter-spacing: 0.24px;
  margin-bottom: 3rem;
}
.companyColabText {
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 2.7rem;
  margin-bottom: 5rem;
}
.brandContainer {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  background-color: $color-white;
  gap: 1px;
}
.brandImageContainer {
  min-height: 126px;
  padding: 10%;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: $backGround-color;
  img {
    max-width: 100%;
  }
}
@media screen and (min-width: 1500px) {
  .imageContainer {
    padding: 0 12%;
  }
}
@media screen and (max-width: 576px) {
  .container1 {
    padding-top: 10rem;
    margin-bottom: 1.6rem;
  }
  .container2,
  .container3 {
    margin-bottom: 7rem;
  }
  .heading1 {
    font-size: 3.2rem;
    line-height: 5rem;
    margin-bottom: 1.6rem;
  }
  .text1 {
    font-size: 1.6rem;
    line-height: 2.8rem;
    margin-bottom: 1.4rem;
  }
  .imageContainer {
    padding: 0;
    margin-bottom: 7rem;
    margin-top: 1rem;
  }
  .heading2 {
    font-size: 2.7rem;
    line-height: 3.2rem;
    margin-bottom: 4.4rem;
  }
  .brandContainer {
    grid-template-columns: 50% 50%;
  }
}
