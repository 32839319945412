@import "/src/variable.scss";

.lastContainer1 {
  background-image: url(../../Assets/lastContainer.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  padding: 6% 5%;
  margin-top: 10rem;
}
@media screen and (min-width: 1500px) {
  .lastContainer1 {
    padding:6% 12%;
  }
}
.container1Box {
  background-color: $backGround-color2;
  padding: 4% 8%;
  color: $color-white;
  border-radius: 10px;
  h1 {
    font-weight: 700;
    font-size: 2.8rem;
    line-height: 4.2rem;
  }
  p{
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.8rem;
  }
  .btnContainer{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
.container2Box {
  padding: 0;
  color: $color-white;
  h1 {
    font-weight: 700;
    font-size: 7rem;
    line-height: 8rem;
    font-family: $font-2;
    padding-bottom: 9rem;
  }
  .btnContainer{
    display: flex;
    align-items: center;
  }
}

.btn_contact {
  color: $color-white;
  border-radius: 50vh;
  background-color: $theme-color;
  border: none;
  padding: 1.9rem 2.5rem;
  font-size: 1.5rem;
  line-height: 1.5rem;
  text-decoration: none;
  font-weight: 500;
  &:hover {
    color: $color-white;
  }
  svg {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 576px) {
  .container1Box {
    background-color: $backGround-color2;
    padding: 15px;
    color: $color-white;
    h1 {
      font-size: 1.8rem;
      line-height: 2.8rem;
      text-align: center;
    }
    p{
      font-size: 1.4rem;
      line-height: 2.8rem;
      text-align: center;
    }
    .btnContainer{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .container2Box {
    padding: 0;
    h1 {
      font-size: 2.8rem;
      line-height: 3.8rem;
      padding-bottom: 3rem;
      text-align: center;
    }
    .btnContainer{
      justify-content: center;
    }
  }
}