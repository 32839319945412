// @import "../node_modules/sass-rem";
// @use "rem";

@import "/src/variable.scss";


:root {
  --backGround-color: rgba(0, 12, 36, 1);
  --header_link_inactive: #cdcdcd;
  --header_link_inactive-hover: #ffffff;
  --header_link_active: #ffffff;

  --btn_getStarted-backgroundColor: #1954f4;
  --btn_getStarted-color: #ffffff;
  --contactUs_MapsLink: #ff6846;

  --footer_link-color: #ffffff;
  --footer_link-color-hover: #cdcdcd;

  --card-backgroundColor: #f5f6f9;
  --integration-appCard-backgroundColor: white;
  --integration-appCard-type_Color: #fd9468;
  font-size: 10px;
}


body {
  font-family: $default-font;
  color: $color-white;
}

// typography

.heading1 {
  font-size: 5rem;
  line-height: 8rem;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-weight: 800;
  span {
    color: $theme-color;
  }
}
.heading2 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  span {
    color: $theme-color;
  }
}
.heading3 {
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 65px;
  span {
    color: $theme-color;
  }
}

.title1{
  font-weight: 600;
  font-size: 50px;
  line-height: 40px;
  font-feature-settings: "salt" on, "liga" off;
}
.text1 {
  font-weight: 500;
  font-size: 20px;
  line-height: 35px;
}

.mobShow {
  display: none;
}
.mobHidden {
  display: block;
}

.mainPaddingContainer {
  padding: 0 5%;
}
@media screen and (min-width: 1500px) {
  .mainPaddingContainer {
    padding: 0 12%;
  }
  
}

@media screen and (max-width: 576px) {
  .mainPaddingContainer {
    padding: 0 24px;
  }
  
}



.slick-dots button::before {
  color: rgba(255, 255, 255, 1) !important;
}

.mainPaddingContainer_sm_7 {
  padding: 0 calc(5 * 12% / 7);
}

.footer-container {
  color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  padding: 40px 0px;
  background-color: #00091b;
}

.footer-segment {
  margin-left: 80px;
  margin-right: 80px;
}
@media screen and (min-width: 1500px) {
  .footer-segment {
    margin: 0 12%;
  }
}

.uex-address {
  /* font-family: 'Nexa Light'; */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 23px;
  /* or 192% */

  display: flex;
  align-items: center;

  color: #d0d0d0;
}

.btn_getStarted1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 13px 33px;
  border-radius: 3px;
  transition: all ease-in-out 300ms;
  background-color: var(--btn_getStarted-color);
  color: var(--btn_getStarted-backgroundColor);
  border: none;
}

.btn_getStarted1:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.btn_medium {
  color: $color-white;
  width: auto;
  border-radius: 50vh;
  background-color: $theme-color;
  border: none;
  padding: 1.9rem 2.5rem;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 500;
  svg {
    font-size: 1.8rem;
  }
}

.footer_links {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.footer_links:hover {
  color: var(--footer_link-color-hover);
  text-decoration: none;
}

.footer_rights {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.252778px;
  color: #767676 !important;
  text-decoration: none;
  margin: 0 8px;
}

.form-switch .form-check-input {
  height: 30px;
  width: 70px;
}

.form-switch .form-check-input:focus {
  border-color: green !important;
  box-shadow: 0 0 0 0.25rem green;
}

.form-switch .form-check-input:checked {
  background-color: red !important;
}

.accordion-item {
  border-bottom: 1px solid $color-lightGray !important;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.accordion-button{
  cursor: pointer;
}
.accordion-button,
.collapse {
  transition: all ease-in-out 0.15s;
  background-color: $backGround-color3;
}

.accordion-button:not(.collapsed) {
  background-color: $backGround-color3;
}
.accordion-button::after {
  background-image: url(Assets/addIcon.svg);
}
.accordion-button:not(.collapsed)::after {
  background-image: url(Assets/subtractIcon.svg);
}

.collapsed h4 {
  color: rgba(59, 58, 64, 1);
}

input:focus,
select:focus,
textarea:focus {
  outline-color: var(--btn_getStarted-color);
}

.sliderContinerMain {
  position: relative;
}
.slick-arrow {
  display: none !important;
}

.btn_slider {
  position: absolute;
  top: 50%;
}
.slick-dots .slick-active button:before {
  color: var(--btn_getStarted-color) !important;
}
@media screen and (max-width: 576px) {
  // typography
.heading1 {
  font-size: 28px;
  line-height: 42px;
}
.heading2 {
  font-size: 26px;
  line-height: 28px;
}
.heading3 {
  font-size: 26px;
  line-height: 28px;
}

.title1{
  font-size: 32px;
  line-height: 40px;
}
.text1 {
  font-size: 16px;
  line-height: 24px;
}
  .mobShow {
    display: block;
  }
  .mobHidden {
    display: none;
  }
  .mobTextCenter {
    text-align: center;
  }
  .mainPaddingContainer_sm_7 {
    padding: 0 2%;
  }
  .footer-segment {
    margin-left: 24px;
    margin-right: 24px;
  }
}
