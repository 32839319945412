@import url("https://fonts.googleapis.com/css?family=Roboto:700");


.startingAnimation {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 3000;
  background: #000c24;
  animation: fadeStartingAnimation ease-in-out 0.6s forwards;
  -moz-animation: fadeStartingAnimation ease-in-out 0.6s forwards;
  -webkit-animation: fadeStartingAnimation ease-in-out 0.6s forwards;
  -o-animation: fadeStartingAnimation ease-in-out 0.6s forwards;
  animation-delay: 2.5s;
}
.containerr {
  width: 100%;
  min-height:100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image {
  perspective: 3000px;
  position: absolute;
  animation: fade ease-in-out 0.6s forwards;
  -moz-animation: fade ease-in-out 0.6s forwards;
  -webkit-animation: fade ease-in-out 0.6s forwards;
  -o-animation: fade ease-in-out 0.6s forwards;
  animation-delay: 2.5s;
  max-height: 9rem;
  height: 100%;
}
.image img {
  animation: swing ease-in-out 2s;
  -moz-animation: swing ease-in-out 2s;
  -webkit-animation: swing ease-in-out 2s;
  -o-animation: swing ease-in-out 2s;
  transform-style: preserve-3d;
  max-height: 9rem;
  height: 100%;
  width: auto;
}
.image:hover img {
  transform: rotateX(0deg) rotateZ(0deg) translate3d(0px, 0px, 0px);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

@keyframes swing {
  0% {
    transform: rotateX(70deg) rotateZ(-60deg) translate3d(-120px, 0px, 70px);
  }
  100% {
    transform: rotateX(0deg) rotateZ(0deg) translate3d(0px, 0px, 0px);
  }
}

@keyframes fade {
  from {
    opacity: 1;
    
  }
  to {
    opacity: 0;
    transform: scaleY(300%) scaleX(300%);
    display: none;
  }
}
@keyframes fadeStartingAnimation {
  0%{ 
    opacity: 1;
    display: none;
  }
  99%{
    opacity: 0;
    display: none;
    z-index: 0;
    height: 100vh;
  }
  100%{
    height: 0;
  }
}

