@import "/src/variable.scss";

.container1 {
  margin-top: 12rem;
  margin-bottom: 10rem;
  .heading {
    margin-bottom: 4rem;
  }
}
.btnContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.socialContainer {
  display: flex;
  margin-bottom: 20rem;
  .socialIcon {
    font-size: 24px;
    color: $color-white;
    font-size: 2.4rem;
    font-weight: 700;
  }
}
.divider {
  color: $divider-color;
}

.container2 {
  margin-bottom: 20rem;
}

.container3 {
  margin-bottom: 20rem;
  .heading {
    margin-bottom: 3rem;
  }
  .feedbackBox {
    margin-bottom: 5rem;
    .feedbackCard {
      .circle {
        height: 6.5rem;
        width: 6.5rem;
        border-radius: 50%;
        background-color: $theme-color;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        h1 {
          padding-left: 3rem;
          margin-bottom: 0;
          font-weight: 700;
          font-size: 3.4rem;
          line-height: 3.2rem;
        }
      }
      .textBox {
        padding-left: 3rem;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2.8rem;
      }
    }
  }
  .points {
    .tick {
      background-color: $theme-color;
      border-radius: 50%;
      padding: 5px;
      margin-right: 2.2rem;
      margin-left: 2rem;
      svg {
        height: 18px;
        width: 18px;
      }
    }
    .textContainer {
      p {
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 2.8rem;
        margin-bottom: 4rem;
      }
    }
  }
}
.container4 {
  margin-bottom: 20rem;
  img {
    width: 100%;
  }
  .heading {
    margin-bottom: 10rem;
  }
  .recentType {
    margin-top: 50px;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 2.3rem;
    text-transform: uppercase;
  }
  .workContainer {
    margin-bottom: 10rem;
    .recentTextContainer {
      padding: 0 10%;
      .workImg {
        height: 60px;
        width: auto;
      }
      .title {
        margin-top: 30px;
        margin-bottom: 70px;
      }
    }
  }
}
.container5 {
  margin-bottom: 20rem;
  .heading {
    margin-bottom: 3rem;
  }
  .text {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 576px) {
  .container1 {
    margin-top: 16rem;
    margin-bottom: 7rem;
    .heading {
      margin-bottom: 3rem;
      text-align: center;
    }
    .text {
      text-align: center;
      margin-bottom: 7rem;
    }
  }
  .btnContainer {
    justify-content: center;
  }
  .socialContainer {
    display: flex;
    margin-bottom: 7rem;
    .socialIcon {
      font-size: 15px;
    }
  }

  .container2 {
    margin-bottom: 7rem;
  }

  .container3 {
    margin-bottom: 7rem;
    .heading {
      margin-bottom: 2.5rem;
      text-align: center;
    }
    .text {
      text-align: center;
      margin-bottom: 5.7rem;
    }
    .feedbackBox {
      margin-bottom: 5rem;
      .feedbackCard {
        .circle {
          height: 4.5rem;
          width: 4.5rem;
          margin-bottom: 10px;
          h1 {
            padding-left: 2.5rem;
            font-size: 2.6rem;
            line-height: 3.2rem;
          }
        }
        .textBox {
          padding-left: 1rem;
          font-size: 1.2rem;
        }
      }
    }
    .points {
      .tick {
        padding: 5px;
        margin-right: 1.5rem;
        margin-left: 0rem;
      }
      .textContainer {
        p {
          text-align: justify;
        }
      }
    }
  }
  .container4 {
    margin-bottom: 7rem;
    img {
      width: 100%;
    }
    .heading {
      margin-bottom: 10rem;
      text-align: center;
    }
    .recentType {
      margin-top: 50px;
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 2.3rem;
      text-transform: uppercase;
    }
    .workContainer {
      margin-bottom: 10rem;
      .recentTextContainer {
        padding: 0 10%;
        .workImg {
          height: 40px;
          margin-top: 30px;
        }
        .title {
          margin-top: 20px;
          margin-bottom: 30px;
        }
      }
    }
  }
  .container5 {
    margin-bottom: 7rem;
    .heading {
      margin-bottom: 2rem;
      text-align: center;
    }
    .text {
      margin-bottom: 50px;
      text-align: center;
    }
  }
}
