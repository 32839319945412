@import "/src/variable.scss";

.serviceCard {
  color: $color-white;
  background-color: $backGround-color2;
  padding: 3rem 4rem;
  border-radius: 10px;
  height: 100%;
  margin: 0;
  transition: all ease-in-out 0.2s;
  
}
.serviceCard:hover{
  transform: scale(1.05);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
.titleContainer {
  display: flex;
  margin-bottom: 2.5rem;
  h1 {
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 2.7rem;
    letter-spacing: 0.183333px;
  }
}
p {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 3.2rem;
  margin-bottom: 2.5rem;
}

.tagContainer {
  display: flex;
  flex-wrap: wrap;
  span {
    background-color: $backGround-color3;
    padding: 14px 27px;
    border-radius: 6px;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2rem;
    letter-spacing: 0.4px;
    margin-bottom: 10px;
    &:nth-child(n + 1) {
      margin-right: 18px;
    }
  }
}

@media screen and (max-width: 576px) {
  p {
    font-size: 1.5rem;
    line-height: 2.8rem;
    margin-bottom: 1.5rem;
  }
}